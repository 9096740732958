// List of terms in errors that should be ignored
const ignoreErrorsList = [
    // jQuery related
    "$",
    "jquery",
    "jq",
    "jQuery is not defined",
    
    // Google Tag Manager related
    "gtm",
    "gtag",
    "googletagmanager",
    "google tag manager",
    "gtm.js",
    "gtm-",
    
    // Google Analytics related
    "ga",
    "google-analytics",
    "analytics.js",
    "google analytics",
    "dataLayer",
    "_gaq",
    "fbq",  // Facebook Pixel
    "fbevents",

    // Old iOS behaviour
    "/apple-touch-icon",
    // Other iOS related
    "/.well-known",
];

// List of error parts that should be ignored in client errors
const clientIgnoreErrorsList = [
    "Page data not found:",  // This is already handled server side
]

// Ignore transactions that contain these strings
const ignoreErrorsTransactionList = [
    "Page.generateMetadata",
]

export const handleSentryIgnore = (event, isClient = false) => {
    // Filter out Google Tag Manager, jQuery and Google Analytics related errors
    if (event.exception && event.exception.values) {
        // Combine the ignore lists if we're in the client
        const ignoreCombinedErrors = isClient ?
            clientIgnoreErrorsList.concat(ignoreErrorsList) :
            ignoreErrorsList;

        // Check if the error is in the ignore list
        const hasIgnoredError = event.exception.values.some(
            ({ value }) => value && ignoreCombinedErrors.some(term => 
                value.toLowerCase().includes(term.toLowerCase())
            )
        );
        // Check if the transaction is in the ignore list
        const hasIgnoredTransaction = event.transaction && ignoreErrorsTransactionList.some(term => 
            event.transaction.toLowerCase().includes(term.toLowerCase())
        );
        if (hasIgnoredError || hasIgnoredTransaction) {
            return null; // Drop errors containing any ignored terms
        }
    }

    return event;
}
