// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"8o-4ymj1ZZxy1Q0NAXxQp"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import { handleSentryIgnore } from './src/utils/sentryIgnore';

console.log('sentry.client.config.js');
const SentrySettings = {
  dsn: "https://438e14215531476fb6c08e66fe8d837a@o4507353609470032.ingest.de.sentry.io/4507353609601106",
  // Add optional integrations for additional features
  integrations: [],
  // Sample only 10% of traces in production
  tracesSampleRate: 0.1,
  // Sample only 20% of errors in production
  sampleRate: 0.2,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  environment: "NextJS-Init",

  // Called for message and error events
  beforeSend(event) {
    return handleSentryIgnore(event, true)
  },
}

fetch('/api/config/env')
  .then((response) => response.json())
  .then((data) => {
    const { environment } = data;
    if (environment !== 'NextJS-Development') {
      // Disable Sentry for development
      // When debugging, edit this
      SentrySettings.environment = environment;
      Sentry.init(SentrySettings);
    };
  })
  .catch((error) => {
    console.error("Error fetching environment:", error);
  });
